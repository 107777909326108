import type { DurationUnits, Zone } from 'luxon';
import { DateTime as LuxonDateTime } from 'luxon';

import { Clock } from './clock';
import { Duration } from './duration';
import { DayOfWeek, TimeOfDay } from './time-of-day';

export class DateTime {
	/**
	 * Get the current computer timezone
	 * Being explicit on the timezone prevents issues with how libraries handle timezones.
	 */
	static getTimeZone(): string {
		// We are using the Intl API to get the timezone of the computer
		// This "should" be syced with the timezone of user profile
		// By explicity setting the timezone we prevent issues with how libraries determine "local"
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	/**
	 * Returns the given data as a DateTime object in UTC.
	 * @param date - a JavaScript Date object
	 * @param options.zone - the zone to place the DateTime into
	 */
	static fromDateAsUTC(date: Date, options?: { zone?: string | Zone }): DateTime {
		return new DateTime(LuxonDateTime.fromJSDate(date, options).setZone('utc'));
	}

	/**
	 * Returns the given data as a DateTime object in computer timezone
	 * @param date - a JavaScript Date object
	 * @param options.zone - the zone to place the DateTime into
	 */
	static fromDate(date: Date, options?: { zone?: string | Zone }): DateTime {
		return new DateTime(LuxonDateTime.fromJSDate(date, options).setZone(DateTime.getTimeZone()));
	}

	/**
	 * Returns the given data as a DateTime object in computer timezone
	 * @param text - a date string
	 * @param fmt - the format of the date string
	 * @param options.zone - the zone to place the DateTime into
	 */
	static fromDateString(text: string, fmt = 'yyyy-MM-dd', options?: { zone?: string | Zone }): DateTime {
		return new DateTime(LuxonDateTime.fromFormat(text, fmt, options));
	}

	/**
	 * Convert the given ISO string to a DateTime object in UTC.
	 * @param text - an ISO string
	 * @param options.zone - the zone to place the DateTime into
	 */
	static fromISOStringAsUTC(text: string, options?: { zone?: string | Zone }): DateTime {
		return new DateTime(LuxonDateTime.fromISO(text, options).setZone('utc'));
	}

	/**
	 * Convert the givent ISO string to a DateTime object in computer timezone.
	 * @param text - an ISO string
	 * @param options.zone - the zone to place the DateTime into
	 */
	static fromISOString(text: string, options?: { zone?: string | Zone }): DateTime {
		return new DateTime(LuxonDateTime.fromISO(text, options).setZone(DateTime.getTimeZone()));
	}

	/**
	 * Convert the givent date object to a DateTime object in computer timezone.
	 * @param date - a date object
	 * @param options.zone - the zone to place the DateTime into
	 */
	static fromObject(
		date: {
			year: number;
			month: number;
			date?: number;
			hours?: number;
			minutes?: number;
			seconds?: number;
		},
		options?: { zone?: string | Zone }
	): DateTime {
		return new DateTime(
			LuxonDateTime.fromObject(
				{
					year: date.year,
					month: date.month,
					day: date.date || 1,
					hour: date.hours || 0,
					minute: date.minutes || 0,
					second: date.seconds || 0,
				},
				options
			).setZone(DateTime.getTimeZone())
		);
	}

	static now(): DateTime {
		return DateTime.fromDate(Clock.now());
	}

	static max(...dateTimes: (DateTime | null | undefined)[]): DateTime {
		return new DateTime(
			LuxonDateTime.max(
				...dateTimes.filter((dateTime): dateTime is DateTime => !!dateTime).map((dateTime) => dateTime.date)
			)
		);
	}

	static isValidDateString(text: string, options?: { zone?: string | Zone }): boolean {
		return LuxonDateTime.fromISO(text, options).isValid;
	}

	private constructor(private date: LuxonDateTime) {
		this.date = date;
	}

	getLuxonDate(): LuxonDateTime {
		return this.date;
	}

	get isValid(): boolean {
		return this.date.isValid;
	}

	get fullYear(): number {
		return this.date.year;
	}

	get year(): number {
		return this.date.year;
	}

	get month(): number {
		return this.date.month;
	}

	get ordinal(): number {
		return this.date.ordinal;
	}

	get weekday(): number {
		return this.date.weekday;
	}

	get dayOfWeek(): DayOfWeek {
		return DayOfWeek.fromISOWeekday(this.weekday);
	}

	get weekDayLong(): string {
		const weekDayLong = this.date.weekdayLong;

		if (!weekDayLong) {
			throw new Error('Invalid weekDayLong');
		}

		return weekDayLong;
	}

	get hours(): number {
		return this.date.hour;
	}

	get minutes(): number {
		return this.date.minute;
	}

	get seconds(): number {
		return this.date.second;
	}

	withTime(time: TimeOfDay): DateTime {
		return new DateTime(this.date.set({ hour: time.hours, minute: time.minutes, second: time.seconds }));
	}

	isBefore(date: DateTime): boolean {
		// https://moment.github.io/luxon/docs/manual/math.html
		return this.date < date.date;
	}

	isBeforeOrEqual(date: DateTime): boolean {
		return this.date <= date.date;
	}

	isAfter(date: DateTime): boolean {
		// https://moment.github.io/luxon/docs/manual/math.html
		return this.date > date.date;
	}

	isAfterOrEqual(date: DateTime): boolean {
		return this.date >= date.date;
	}

	isSameDate(date: DateTime): boolean {
		return this.date.equals(date.getLuxonDate());
	}

	isSameDay(date: DateTime): boolean {
		return this.date.year === date.year && this.date.ordinal === date.ordinal;
	}

	isPast(): boolean {
		return this.isBefore(DateTime.now());
	}

	isFuture(): boolean {
		return this.isAfter(DateTime.now());
	}

	toISOString(): string {
		const isoString = this.date.toISO();

		if (!isoString) {
			throw new Error('Invalid ISO string');
		}

		return isoString;
	}

	toUTC(): DateTime {
		return new DateTime(this.date.toUTC());
	}

	toISODateString(): string {
		const isoDateString = this.date.toISODate();

		if (!isoDateString) {
			throw new Error('Invalid ISO date string');
		}

		return isoDateString;
	}

	toDateString(): string {
		return this.date.toLocaleString({
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}

	toDateTimeString(format = LuxonDateTime.DATETIME_MED): string {
		return this.date.toLocaleString(format);
	}

	toDate(): Date {
		return this.date.toJSDate();
	}

	toFormat(format: string): string {
		return this.date.toFormat(format);
	}

	toMillis(): number {
		return this.date.toMillis();
	}

	addSeconds(seconds: number): DateTime {
		return new DateTime(this.date.plus({ seconds }));
	}

	addMinutes(minutes: number): DateTime {
		return new DateTime(this.date.plus({ minutes }));
	}

	addDays(days: number): DateTime {
		return new DateTime(this.date.plus({ days }));
	}

	subtractDays(days: number): DateTime {
		return new DateTime(this.date.minus({ days }));
	}

	addMonths(months: number): DateTime {
		return new DateTime(this.date.plus({ months }));
	}

	addYears(years: number): DateTime {
		return new DateTime(this.date.plus({ years }));
	}

	startOfDay(): DateTime {
		return new DateTime(this.date.startOf('day'));
	}

	endOfDay(): DateTime {
		return new DateTime(this.date.endOf('day'));
	}

	startOfMonth(): DateTime {
		return new DateTime(this.date.startOf('month'));
	}

	endOfMonth(): DateTime {
		return new DateTime(this.date.endOf('month'));
	}

	valueOf(): number {
		return this.date.valueOf();
	}

	diff(otherDateTime: DateTime, unit?: DurationUnits, options?: { zone?: string | Zone }): Duration {
		const luxonDateTime = LuxonDateTime.fromISO(otherDateTime.toISOString(), options).setZone(
			DateTime.getTimeZone()
		);

		const diffISO = this.date.diff(luxonDateTime, unit).toISO();

		if (!diffISO) {
			throw new Error('Invalid diff');
		}

		return Duration.fromISO(diffISO);
	}

	withZone(zone: string, options?: { keepLocalTime: boolean }): DateTime {
		return new DateTime(this.date.setZone(zone, options));
	}

	getQuarter(): number {
		return Math.floor((this.date.month - 1) / 3) + 1;
	}
}
