import { FC, ReactNode, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';

import FullCalendarStyles from './FullCalendarStyles';
import GlobalStyles from './GlobalStyles';

const CustomStyles = createGlobalStyle`
	html, body {
		font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
	}

	button {
		appearance: button;
		background-color: transparent;
	}
`;

export const ThemeProvider: FC<{ children?: ReactNode }> = ({ children }) => {
	useEffect(() => {
		if (document.getElementById('google-fonts-inter')) return;

		const link = document.createElement('link');
		link.setAttribute(
			'href',
			'https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'
		);
		link.setAttribute('rel', 'stylesheet');
		link.setAttribute('id', 'google-fonts-inter');
		document.getElementsByTagName('head')[0].appendChild(link);
	}, []);

	return (
		<>
			<GlobalStyles />
			<CustomStyles />
			<FullCalendarStyles />
			{children}
		</>
	);
};
