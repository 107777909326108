import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

import { StyledText } from './Text.styles';

export type Align = 'center' | 'left' | 'right';
export type Color = 'current' | 'gray' | 'subdued' | 'white' | 'red';
export type Size = 'small' | 'medium' | 'large';
export type Weight = 'current' | 'normal' | 'semibold' | 'bold';
export type Overflow = 'clip' | 'ellipsis' | 'multiline';

export interface Props {
	align?: Align;
	color?: Color;
	size?: Size;
	weight?: Weight;
	children?: ReactNode;
	overflow?: Overflow;
	as?: 'div' | 'span' | 'p' | 'label';
}

export const Text: FC<Props & ComponentPropsWithoutRef<'p'>> = ({
	as = 'p',
	align = 'left',
	children,
	color = 'gray',
	size = 'medium',
	weight = 'normal',
	overflow = 'clip',
	...props
}) => (
	<StyledText as={as} align={align} color={color} size={size} weight={weight} overflow={overflow} {...props}>
		{children}
	</StyledText>
);
