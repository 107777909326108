import { createGlobalStyle } from 'styled-components';

import { theme } from '../tailwind.config';

export default createGlobalStyle`
	.fc-timeline-event-harness:not(:has(.custody)):not(:has(.retired)):not(:has(.unavailable)):hover,
	.fc-daygrid-event-harness:not(:has(.custody)):not(:has(.retired)):not(:has(.unavailable)):hover,
	.fc-timegrid-event-harness:not(:has(.custody)):not(:has(.retired)):not(:has(.unavailable)):hover {
		filter: brightness(1.1);
	}

	.fc-timeline-event-harness a,
	.fc-daygrid-event-harness a,
	.fc-timegrid-event-harness a {
		border: none;
	}

	.fc-timeline-event-harness:has(.is-draft) {
		opacity: 0.5;
	}

	.fc-h-event {
		font-weight: 600;
		text-decoration: none !important;

		&.fc-timeline-event {
			height: 40px;
		}

		&.custody {
			background-color: ${theme.colors.blue[100]};
			background-image: linear-gradient(45deg,#fff 25%,transparent 0,transparent 50%,#fff 0,#fff 75%,transparent 0,transparent);
			background-size: 30px 30px;

			& * {
				color: ${theme.colors.gray[900]} !important;
			}
		}

		&.retired {
			background-color: ${theme.colors.gray[900]};
		}

		&.unavailable {
			background-color: ${theme.colors.gray[100]};
			background-image: linear-gradient(45deg,#fff 25%,transparent 0,transparent 50%,#fff 0,#fff 75%,transparent 0,transparent);
			background-size: 30px 30px;

			& * {
				color: ${theme.colors.gray[900]} !important;
			}
		}
	}
`;
